<script setup lang="ts">
  import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
  import SpaceService from '~/services/SpaceService'

  const pendingSpaces = ref([])

  const approveSpace = async (spaceId) => {
    await SpaceService.approveSpace(spaceId)
    pendingSpaces.value = pendingSpaces.value.filter(
      (space) => space.id !== spaceId
    )
  }

  const showPendingSpace = (space: any) => {
    try {
      const spaceUrl = useUrlHelpers().getSpaceUrl(space)
      window.open(spaceUrl, '_blank')
    } catch (error: any) {}
  }

  onMounted(async () => {
    await nextTick(async () => {
      // @ts-ignore
      pendingSpaces.value = await SpaceService.getPendingSpaces()
    })
  })
</script>
<template>
  <div
    v-if="pendingSpaces.length > 0"
    class="px:12 mt-4 flex flex-col justify-between rounded-md border p-4 md:flex-row md:px-8">
    <table class="hidden min-w-full divide-y divide-gray-300 md:table">
      <thead>
        <tr>
          <th
            scope="col"
            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
            Espaço
          </th>
          <th
            scope="col"
            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            Status
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr
          v-for="space in pendingSpaces"
          :key="space.name">
          <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
            <div class="flex items-center">
              <div class="h-11 w-11 flex-shrink-0">
                <img
                  class="ml-4 h-11 w-11 rounded-full"
                  :src="space.coverPhotoUrl"
                  alt="" />
              </div>
              <div class="ml-4 pl-4">
                <div class="font-medium text-gray-900">
                  {{ space.name }}
                </div>
                <div class="mt-1 text-gray-500">
                  {{ space.address.route }}, {{ space.address.number }},
                  {{ space.address.neighborhood }} -
                  {{ space.address.city }}
                </div>
              </div>
            </div>
          </td>
          <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
            <span
              class="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20"
              >Pendente</span
            >
          </td>
          <td
            class="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <button
              title="Visualizar"
              type="button"
              class="mr-2 inline-flex items-center gap-x-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              @click="showPendingSpace(space)">
              <MagnifyingGlassIcon
                class="-ml-0.5 h-5 w-5"
                aria-hidden="true" />
              Visualizar
            </button>
            <button
              title="Aprovar"
              type="button"
              class="mr-2 inline-flex items-center gap-x-2 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              @click.prevent="approveSpace(space.id)">
              <CheckIcon
                class="-ml-0.5 h-5 w-5"
                aria-hidden="true" />
              Aprovar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <EmptyList>Nenhum espaço encontrado.</EmptyList>
  </div>
</template>
